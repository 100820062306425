import React from "react";
import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {
  Image,
  Box,
  //HStack,
  //Center,
} from "@chakra-ui/react";

const ImageButton = ({ src, link, ...props }: any) => (
  //<Box                  /* ４つ固定横並び（従来版） */
  <Box ml="8px" mr="8px"  /* Case.A, B, C */
    onClick={() => {
      window.location.href = link;
    }}
  >
    <Image src={src} {...props} />
  </Box>
);

export const PartsLogoLink = () => {
  /** Slider Setting
   * Case.A: Position:Left  （ロゴ数制限なし）
   *  centerMode: false
   *  centerPadding: '50px'
   *  slidesToShow: 4
   *  //infinite: false   // ロゴ数4つまではスライドしない様にfalse
   * 
   * Case.B: Position:Center  （ロゴ数が3又は５つ以上必要）
   *  centerMode: true
   *  centerPadding: '60px'
   *  slidesToShow: 3
   *  //infinite: false   // ロゴ数3つの場合はスライドしない様にfalse
   * 
   */
  const settings = {
    centerMode: true, // default: false
    centerPadding: '60px', // default: 50px
    slidesToShow: 3, // default: 1 何件見せるか件数を指定
    infinite: true,
    dots: false,
    arrows: false,  // default: true
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000, // default: 3000 自動スクロールする間隔(ms)
    //slidesToScroll: 1,  // default: 1 何件ずつスライドさせるか件数を指定
    swipeToSlide: true, //default:false   drag/swipeでslidesToScrollの設定を無視するならtrue
    //touchThreshold: 5,  // default: 5
    //focusOnSelect: false,
  }

  return (
    // ４つ固定横並び（（従来版 暖候期）: Nx西iHighway, Nx中iHighway, ドラとら, mewti）
    // <HStack m={4} alignItems="center">
    //   <ImageButton
    //     src={`${process.env.PUBLIC_URL}/images/w-ihighway.png`}
    //     link="https://www.w-nexco.co.jp/realtime/"
    //   />
    //   <ImageButton
    //     src={`${process.env.PUBLIC_URL}/images/c-ihighway.png`}
    //     link="https://www.c-ihighway.jp/pcsite/"
    //   />
    //   {/* <ImageButton
    //     src={`${process.env.PUBLIC_URL}/images/logo_dratra.png`}
    //     w="170px"
    //     link="https://www.drivetraffic.jp/"
    //   /> */}
    //   <ImageButton
    //     src={`${process.env.PUBLIC_URL}/images/logo_drapla.png`}
    //     w="300px"
    //     link="https://www.driveplaza.com/"
    //   />
    //   <ImageButton
    //     src={`${process.env.PUBLIC_URL}/images/mewti.png`}
    //     w="200px"
    //     link="https://search.shutoko-eng.jp/rtmap.html"
    //     transform="translateY(-4px)"
    //   />
    // </HStack>

    // Case.A, B: Slider : Centerの場合は 3 or 5つ以上が望ましい
    // 5つ以上横並び(Slider)（JARTIC, Nx西iHighway, Nx中iHighway, ドラとら, mewti, [阪神]）
    <Box mt="2" mb="2">
      <Slider {...settings}>
        <ImageButton
          src={`${process.env.PUBLIC_URL}/images/jartic_banner_01.png`}
          link="https://www.jartic.or.jp/"
        />
        <ImageButton
          src={`${process.env.PUBLIC_URL}/images/w-ihighway.png`}
          link="https://www.w-nexco.co.jp/realtime/"
          transform="translateY(6px)"
        />
        <ImageButton
          src={`${process.env.PUBLIC_URL}/images/c-ihighway.png`}
          link="https://www.c-ihighway.jp/pcsite/"
          transform="translateY(7px)"
        />
        <ImageButton
          src={`${process.env.PUBLIC_URL}/images/logo_dratra.png`}
          link="https://www.drivetraffic.jp/"
          transform="translateY(3px)"
        />
        <ImageButton
          src={`${process.env.PUBLIC_URL}/images/mewti.png`}
          link="https://search.shutoko-eng.jp/rtmap.html"
          transform="translateY(2px)"
        />
        {/* <ImageButton
          src={`${process.env.PUBLIC_URL}/images/840go.jpg`}
          link="https://www.8405.jp"
          transform="translateY(2px)"
        /> */}
      </Slider>
    </Box>

    //Case.C：２段固定： 5件以上が望ましい（e.g. 上段3件、下段2件）
    // <Box>
    //   <HStack mt={2}>
    //     <Center w='100%' alignItems="center">
    //       <ImageButton
    //         src={`${process.env.PUBLIC_URL}/images/jartic_banner_01.png`}
    //         link="https://www.jartic.or.jp/"
    //         width="90px"
    //       />
    //       <ImageButton
    //         src={`${process.env.PUBLIC_URL}/images/w-ihighway.png`}
    //         link="https://www.w-nexco.co.jp/realtime/"
    //         transform="translateY(3px)"
    //         width="90px"
    //       />
    //       <ImageButton
    //         src={`${process.env.PUBLIC_URL}/images/c-ihighway.png`}
    //         link="https://www.c-ihighway.jp/pcsite/"
    //         transform="translateY(3px)"
    //         width="90px"
    //       />
    //     </Center>
    //   </HStack>
    //   <HStack mt={1} mb={3}>
    //     <Center w='100%' alignItems="center"> 
    //       <ImageButton
    //         src={`${process.env.PUBLIC_URL}/images/logo_dratra.png`}
    //         link="https://www.drivetraffic.jp/"
    //         transform="translateY(3px)"
    //         width="70px"
    //       />
    //       <ImageButton
    //         src={`${process.env.PUBLIC_URL}/images/mewti.png`}
    //         link="https://search.shutoko-eng.jp/rtmap.html"
    //         transform="translateY(-4px)"
    //         width="80px"
    //       />
    //     </Center>
    //   </HStack>
    // </Box> 
  )
}
